<template>
  <app-form-autocomplete
    v-model="course"
    v-model:query="query"
    :autofocus="autofocus"
    :clearable="clearable"
    data-cy-course-autocomplete
    :hide-details="hideDetails"
    item-title="title"
    item-value="id"
    :items="results"
    :label="label"
    :menu="menu"
    placeholder="Search by title"
  >
    <template #item="{ item: result, props }">
      <v-list-item
        class="min-w-[600px] !max-w-[700px]"
        v-bind="props"
        density="compact"
        :prepend-avatar="result.teacher.avatar_url"
        :subtitle="result.teacher.full_name"
        :title="result.title"
      >
        <template #append>
          <v-chip label size="x-small">
            {{ result.content_type }} -
            {{ useCourseFormatLabel(result.format).value }}
          </v-chip>
        </template>
      </v-list-item>
    </template>
  </app-form-autocomplete>
</template>

<script lang="ts" setup>
import { useFuse } from "@vueuse/integrations/useFuse";

import { useCourseFormatLabel } from "#imports";
import type { CourseAutocompleteView } from "~/business-areas/course/course.model";

const model = defineModel<DatabaseTable<"courses">["id"] | null | undefined>({
  default: undefined,
});

const course = defineModel<CourseAutocompleteView | undefined>("course", {
  default: undefined,
});

const properties = withDefaults(
  defineProps<{
    exclude?: DatabaseTable<"courses">["id"][];
    autofocus?: boolean;
    menu?: boolean;
    hideDetails?: boolean;
    label?: string;
    clearable?: boolean;
  }>(),
  {
    exclude: () => [],
    label: undefined,
  },
);

const client = useDatabaseClient();

const { data } = useAsyncData(
  async () => {
    const { data } = await client
      .from("courses")
      .select(
        "id, title, content_type, format, teacher: teachers(user_id, avatar_url, full_name)",
      )
      .not("id", "in", `(${properties.exclude.join(",")})`)
      .returns<CourseAutocompleteView[]>();

    return data ?? [];
  },
  {
    default: () => [],
  },
);

const query = ref("");

const fuse = useFuse(query, data, {
  fuseOptions: { keys: ["title", "teacher.full_name"], threshold: 0.3 },
  matchAllWhenSearchEmpty: true,
});

const results = computed(() => fuse.results.value.map((r) => r.item));

watch(
  data,
  (courses) => {
    course.value = courses.find((course) => course.id === model.value);
  },
  { immediate: true },
);

watch(course, (value) => {
  model.value = value?.id;
});
</script>
