import type { MaybeRef } from "@vueuse/core";

export const useCourseFormatLabel = (
  format: MaybeRef<DatabaseCompositeType<"course_format">>,
) => {
  return computed(() => {
    const { amount, duration } = unref(format);

    const isFullDay = duration === "P1D";
    const isHalfDay = duration === "P0.5D";
    const isRecurring = (amount ?? 0) > 1;

    if (isFullDay && !isRecurring) return "1d";
    if (isHalfDay && !isRecurring) return "1/2d";

    const formattedDuration = formatDuration(duration ?? "");

    if (!formattedDuration) {
      useMonitoring().logError(new TypeError("Invalid duration : " + duration));

      return "";
    }

    const durationEntry = Object.entries(formattedDuration).find(
      (entry) => entry[1] !== undefined,
    );

    if (!durationEntry) return "";

    const quantity = isFullDay
      ? "1d"
      : // eslint-disable-next-line unicorn/no-nested-ternary
        isHalfDay
        ? "1/2d"
        : durationEntry[1] + durationEntry[0].charAt(0);

    return `${(amount ?? 0) > 1 ? `${amount} x ` : ""}${quantity}`;
  });
};
